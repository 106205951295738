@use 'global' as *;
.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: rfs(58px 10px);
  max-height: 90vh;
  max-width: 350px;
  width: 100%;

  @include media($small, down) {
    max-height: 100vh;
  }
}

.header {
  color: var(--color-black);
  font-size: 24px;
  line-height: 1.17;
  margin-bottom: 14px;
  width: 100%;

  @include media($small) {
    text-align: center;
  }
}

.subheader {
  color: var(--color-red-primary);
  display: block;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.43px;
  line-height: 1.5;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.outer_container {
  display: flex;
  justify-content: center;
  max-height: 90vh;

  @include media($small, down) {
    max-height: 100vh;
  }
}

// --------------------------------------------------------- | Step One

.button_container {
  > * {
    display: block;
    text-align: center;
    width: 100%;
  }
}

.subheader_container {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .subheader {
    margin-bottom: 0;
  }

  button {
    @include font-size(12px, 20px);

    color: var(--color-gray-4);
    display: block;
    font-family: var(--font-family-medium);
    text-decoration: underline;
  }
}

.locations_container {
  background-color: var(--color-gray-1);
  display: flex;
  flex: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 20px;
  padding: 10px 10px 0;

  .location_label {
    @include font-size(12px, 20px);

    background-color: var(--color-white);
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 8px;
    border-radius: 20px;
    box-shadow: 0 0 6px 0 rgb(0 0 0 / 10%), 0 0 2px 0 rgb(0 0 0 / 10%);
    color: var(--color-black);
    margin: 0 10px 10px 0;
    padding: 3px 15px;
  }
}

// --------------------------------------------------------- | Step Two

.checkbox_container_3rows {
  display: flex;
  flex: column wrap;
  height: 90px;
}

.checkbox_container_4rows {
  display: flex;
  flex: column wrap;
  height: 120px;
}
