@use 'global' as *;
$border-radius: 20px;

// ---------------------------------------------------------

.theme_default,
.theme_unified {
  &.tab_container {
    @include font-size(16px, 18px);

    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    width: 100%;

    > span {
      @include font-size(16px, 20px);

      display: block;
      font-family: var(--font-family-base);
      font-weight: 500;
      margin-bottom: 8px;
      width: 100%;
    }
  }

  // ---------------------------------------------------------

  .tabs {
    display: flex;
    width: 100%;

    .tab {
      &:not(:first-child) {
        margin-left: 25px;
      }
    }
  }

  // ---------------------------------------------------------

  .tab {
    align-items: center;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    outline: none;
    padding: rfs(10px 0 8px);
    text-align: center;
    white-space: nowrap;
    width: fit-content;

    &[aria-checked='true'] {
      border-bottom: 2px solid var(--color-red-primary);
      font-family: var(--font-family-medium);
      font-weight: 500;
    }

    &.has_icon {
      .tab_label {
        display: none;
        line-height: 1;
        margin-left: 5px;

        @include media($small) {
          display: block;
        }
      }
    }
  }
}

.theme_default {
  .tabs {
    @include media($small, down) {
      justify-content: space-between;
      overflow-x: auto;
      overflow-y: hidden;
      padding-bottom: 18px;
    }
  }
}

.theme_pill {
  &.tab_container {
    @include font-size(12px, 18px);

    display: inline-block;
    width: 100%;

    > span {
      @include font-size(12px, 20px);

      display: block;
      font-family: var(--font-family-medium);
      font-weight: 500;
      margin-bottom: 8px;
      width: 100%;
    }
  }

  // ---------------------------------------------------------

  .tabs {
    background-color: var(--color-gray-1);
    border-radius: $border-radius;
    display: flex;
  }

  // ---------------------------------------------------------

  .tab {
    align-items: center;
    background-color: transparent;
    border-radius: $border-radius;
    color: var(--color-black-alpha85);
    cursor: pointer;
    display: flex;
    justify-content: center;
    outline: none;
    padding: rfs(9px 0 10px);
    text-align: center;
    transition: background-color ease-in-out 0.25s;
    white-space: nowrap;

    &:hover {
      font-family: var(--font-family-medium);
    }

    &[aria-checked='true'] {
      background-color: var(--color-white);
      box-shadow: 0 0 6px 0 rgb(0 0 0 / 10%), 0 0 2px 0 rgb(0 0 0 / 10%) !important;
      font-family: var(--font-family-medium);
    }

    &.has_icon {
      .tab_label {
        display: none;
        line-height: 1;
        margin-left: 5px;

        @include media($small) {
          display: block;
        }
      }
    }
  }
}
