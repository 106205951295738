@use 'global' as *;
.modal {
  background-image: none;
  bottom: 0;
  height: 100%;
  left: 0;
  max-width: 750px;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;

  @include media($small) {
    bottom: unset;
    height: fit-content;
    left: 50%;
    max-height: 100%;
    padding: 15px 0;
    top: 25%;
    transform: translate(-50%, -25%);
  }

  @include media($small, down) {
    overflow: scroll;
  }

  &.modal_property_images {
    max-width: 750px;

    @include media($medium) {
      max-width: 950px;
    }
  }

  &.modal_advanced_search {
    max-width: $small;
    padding: 0;

    @include media($small, down) {
      overflow: visible;
      position: absolute;
    }
  }

  &.modal_pre_approval {
    @include media($small) {
      max-width: 550px;
    }
  }
}

.modal_advanced_search {
  & .modal_inner {
    max-height: 100vh !important;
    overflow: visible;
    overflow-y: auto;
    padding: 0;

    @include media($small, down) {
      overflow: scroll;
      position: absolute;
    }
  }

  .modal_inner::-webkit-scrollbar-thumb {
    background: var(--color-red-primary);
    border-radius: 5%;
    height: 40px;
  }
}

.modal_inner {
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-4);
  border-radius: 4px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 750px;
  overflow: auto;
  padding: rfs(58px 10px);
  position: relative;
  width: 100%;

  .modal_notes & {
    padding: 0;
  }

  .modal_property_images & {
    max-width: 750px;

    @include media($medium) {
      max-width: 950px;
    }
  }

  @include media($small) {
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    height: fit-content;
    max-height: 90dvh;
    padding: rfs(58px);
  }

  @include media($small, down) {
    background-image: none !important;
    height: 100%;

    > div {
      height: 100%;
      margin: 0 auto;
    }
  }
}

.modal:focus:not(:focus-visible) {
  box-shadow: none;
  outline: none;
}

// ---------------------------------------------------------

.modal_button {
  cursor: pointer;
}

// ---------------------------------------------------------

.modal_overlay {
  background-color: var(--color-gray-4-alpha40);
  bottom: 0;
  height: 100%;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: var(--z-index-plus-four);
}

.modal_alert {
  display: block;
  left: 50%;
  max-height: 100%;
  top: 25%;
  transform: translate(-50%, -25%);

  @include media($small-lowest) {
    height: fit-content;
    max-width: 400px;
    padding: 15px 0;
  }

  .modal_inner {
    background-image: none !important;
    height: 100%;
    overflow: hidden;
    width: 100%;
  }
}

.modal_notes {
  border: none;
  display: block;
  max-width: 562px;
  width: 100%;
}

// ---------------------------------------------------------

.modal_body_class {
  overflow: hidden;
}

// ---------------------------------------------------------

.close_button {
  $size: 48px;

  color: var(--color-gray-4);
  height: $size;
  position: absolute;
  right: 0;
  top: 0;
  width: $size;
  z-index: var(--z-index-plus-four);

  > div {
    display: flex;
    justify-content: center;
  }

  svg {
    $size: 14px;

    height: $size;
    width: $size;
  }
}
