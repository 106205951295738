@use 'global' as *;
.search_container {
  padding: rfs(65px 20px 0);
  width: 100%;
  z-index: var(--z-index-plus-two);

  @include media($x-small-highest) {
    padding-left: rfs(54px);
    padding-right: rfs(54px);
  }
}

.headline_container {
  h1 {
    font-size: 20px;
    margin-bottom: 12px;
    opacity: 0%;
    transition: opacity 0.3s ease-in;

    @include media($x-small-lowest) {
      font-size: 24px;
    }

    @include media($x-small-highest) {
      font-size: 28px;
    }

    @include media($small-lowest) {
      font-size: 36px;
    }

    @include media($small) {
      font-size: 48px;
    }

    &.active {
      opacity: 100%;
      transition: opacity 0.3s ease-in-out;
    }
  }
}

.quick_link_container {
  padding: rfs(0 20px 65px);

  @include media($x-small-highest) {
    padding-left: rfs(54px);
    padding-right: rfs(54px);
  }
}

// ---------------------------------------------------------

.hidden {
  visibility: hidden;
}

// ---------------------------------------------------------

.tab_container {
  margin-bottom: 15px;
}

// ---------------------------------------------------------

.search_bar {
  background-color: var(--color-white);
  box-shadow: 0 0 12px 0 rgb(0 0 0 / 10%), 0 0 2px 0 rgb(0 0 0 / 10%);
  display: flex;
  height: 48px;
  margin-bottom: 12px;
  position: relative;
  transition: box-shadow 0.25s ease-in-out;
  z-index: var(--z-index-plus-one);

  &:focus-within,
  &:hover {
    box-shadow: 0 0 12px 0 rgb(0 0 0 / 14%), 0 0 4px 0 rgb(0 0 0 / 14%);
  }

  input {
    border: none;
    font-family: var(--font-family-base);
    padding: 16px;
    width: 100%;

    &::placeholder {
      color: var(--color-gray-5) !important;
      font-family: var(--font-family-base);
    }

    &:focus {
      outline: none;
    }
  }
}

.search_button_container {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 11px;
  position: relative;
  width: 40px;
}

.search_button {
  $size: 36px;

  align-items: center;
  background-color: var(--color-gray-1);
  border-radius: 50%;
  color: var(--color-black);
  display: flex;
  height: $size;
  justify-content: center;
  transition: background-color 0.25s ease-in-out;
  width: $size;

  @include hover {
    background-color: var(--color-gray-2);
  }
}

.search_dropdown {
  background-color: var(--color-white);
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 10%), 0 0 2px 0 rgb(0 0 0 / 10%);
  max-height: 55vh;
  overflow-x: hidden;
  position: absolute;
  top: 48px;
  width: 100%;
}

// ---------------------------------------------------------

.category_label {
  @include font-size(12px, 18px);

  align-items: center;
  color: var(--color-gray-4);
  display: flex;
  font-family: var(--font-family-medium);
  letter-spacing: 0.5px;
  margin: 20px 20px 6px;
  text-transform: uppercase;

  span {
    display: block;
  }

  svg {
    align-self: center;
    display: flex;
    height: 16px;
    margin-right: 10px;
  }
}

.category_items {
  @include reset-list;
}

.category_items,
.category_items_partial_street_search {
  &:last-of-type {
    margin-bottom: 20px;
  }

  li {
    @include hover {
      background-color: var(--color-gray-2);
      outline: none;
    }
  }

  a {
    @include font-size(15px, 28px);

    color: var(--color-black);
    display: block;
    padding: 0 20px 0 46px;

    span {
      font-size: 10px;
      margin-left: 2px;
    }
  }
}

.pseudo_focus {
  background-color: var(--color-gray-2);
  outline: none;
}

.category_items_partial_street_search {
  margin-top: 20px;

  @include hover {
    background-color: var(--color-gray-2);
    outline: none;
  }
}

// ---------------------------------------------------------

.advanced_search_link {
  @include font-size(12px, 20px);

  @include hover {
    cursor: pointer;
  }

  color: var(--color-gray-4);
  display: block;
  font-family: var(--font-family-medium);
  margin-bottom: 20px;
  text-decoration: underline;
}

.no_result {
  padding: 16px;

  p {
    margin-bottom: 14px;
  }

  .advanced_search_link {
    color: var(--color-red-primary);
    margin: 4px auto 0;
  }
}

// ---------------------------------------------------------

.search_filters {
  $margin: 12px;

  display: flex;
  flex-wrap: wrap;
  margin-bottom: -$margin;
}

.quick_link {
  margin: 0 5px 10px 0;
  padding: 6px 8px;

  @include media($x-small) {
    margin: 0 6px 12px 0;
    padding: 6px 10px;
  }

  @include media($x-small-highest) {
    margin: 0 12px 12px 0;
    padding: 9px 20px;
  }
}
